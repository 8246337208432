import { useState, useRef, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import './hyperlapse.css'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

export default function Hyperlapse (props) {

    const [scrolled, setScrolled] = useState(0)
    const [hyperlapse, setHyperlapse] = useState(null)

    function updateState () {
        setScrolled(window.pageYOffset)
    }

    useEffect(() => {
        if (hyperlapse) hyperlapse.slickPlay()
        window.addEventListener('scroll', updateState, { passive: true })
        return () => {
            if (hyperlapse) hyperlapse.slickPause()
            window.removeEventListener('scroll', updateState);
        }
    }, [hyperlapse])

    const settings = {
        infinite: true,
        speed: 500,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        vertical: false,
    };

    return <div className="Hyperlapse"><Slider ref={hyperlapse => setHyperlapse(hyperlapse)} {...settings}>
            {/*<div id="hyperlapse0" className="Hyperlapse__hyper Hyperlapse__hyper--intro">
                <div className="Hyperlapse__introtitle">{props.intro.title}</div>
                <div className="Hyperlapse__introsubtitle">{props.intro.subtitle}</div>
                <button className="Hyperlapse__introbutton" onClick={()=>{
                    hyperlapse.slickNext()
                }}>{props.intro.action}</button>
            </div>*/}
            <div id="hyperlapse0" className="Hyperlapse__hyper Hyperlapse__hyper--intro" style="">
                <div className="Hyperlapse__in__title" >{props.intro.title}</div>
                <div className="Hyperlapse__in__subtitle" >{props.intro.subtitle}</div>
            </div>
            {props.elements.map ((el, i) => <div className="Hyperlapse__hyper" style={{marginTop: scrolled/2}} style={{opacity: (1 - window.pageYOffset/window.innerHeight), backgroundColor: el.backgroundColor}}>
                <img className="Hyperlapse__image" style={{marginTop: scrolled*2/3}} src={el.image}/>
                <NavLink to={"/work/"+el.link}><div className="Hyperlapse__info" style={{bottom: (50 + -1 * scrolled*2/3)}}>
                    <div className="Hyperlapse__title" style={{color: el.color}}>{el.title}</div>
                    <div className="Hyperlapse__subtitle" style={{color: el.color}}>{el.subtitle}</div>
                </div></NavLink>
            </div>)}
    </Slider>
        <button onClick={()=>{
            hyperlapse.slickPause()
            hyperlapse.slickPrev()
        }} className="material-icons-outlined Hyperlapse__scroll Hyperlapse__scroll--left" style={{color: 'black'}}>west</button>
        <button onClick={()=>{
            hyperlapse.slickPause()
            hyperlapse.slickNext()
        }} className="material-icons-outlined Hyperlapse__scroll Hyperlapse__scroll--right" style={{color: 'black'}}>east</button>
    </div>
}

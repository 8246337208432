import './index.css'

export default function NotAuthorized() {
  return (
    <img
      className=".NotAuthorized__image"
      alt="Unauthorized"
      src="https://imagedelivery.net/UFXEtuTp-F3STPvnZt0ShA/c2081540-0dd0-4a4b-21a1-e73579f35000/cover"
    />
  );
}

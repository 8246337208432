import './index.css'
import { NavLink } from 'react-router-dom'

import '../../assets/fonts/font.css'

/*
import edm from '../../assets/edm.png'
import getclub from '../../assets/getclub.png'
import madrich from '../../assets/madrich.png'
import narrow from '../../assets/narrow.png'
import pitek from '../../assets/pitek.png'
import simployal from '../../assets/simployal.png'
import stack from '../../assets/stack.png'
import bomj from '../../assets/bomj.png'
import stonks from '../../assets/stonks.png'
import wide from '../../assets/wide.png'
import zuzan from '../../assets/zuzan.png'
import simple from '../../assets/routing.png'
import driver from '../../assets/driver.png'
import jury from '../../assets/jury.png'
*/
import iceberry from '../../assets/iceberry/1.png'


import Hyperlapse0 from '../../assets/hyperlapse0.png'
import Hyperlapse1 from '../../assets/hyperlapse1.png'
import Hyperlapse2 from '../../assets/hyperlapse2.png'
import Hyperlapse3 from '../../assets/hyperlapse3.png'

import OthTitle from '../../assets/onetwohome/1.png'
import Rinkle from '../../assets/rinkle/1.png'
import YetiTile from '../../assets/yeti/1.png'
import DriverTile from '../../assets/driverapp/1.png'
import MCUZTile from '../../assets/mcuz/1.png'
import QRTile from '../../assets/checkqr/1.png'
import ClubTile from '../../assets/clublink/1.png'
import PS5StatusTile from '../../assets/ps5status/1.png'
import ProxyCafeTile from '../../assets/proxycafe/1.png'
import LetsTile from '../../assets/letsmovie/1.png'
import MadrichTile from '../../assets/madrich/1.png'
import OldTile from '../../assets/org/1.png'
import StonksTile from '../../assets/stonks/1.png'
import DocsTile from '../../assets/docs/1.png'
import SimpleTile from '../../assets/simplerouting/1.png'
import SimployalTile from '../../assets/simployal/1.png'
import UptrackTile from '../../assets/uptrack/1.png'

import Hyperlapse from '../../components/hyperlapse/hyperlapse.js'
import Block, { Blocks, CopyrightBlock } from '../../components/block/block.js'

function App() {

  return (<>
    <Hyperlapse
        intro={{
          title: "BEAUTIFUL DESIGN MEETS MODERN TECHNOLOGY",
          subtitle: "B2C products, web and mobile development, startups and SaaS by a cool team of devs",
          action: ""
        }}

        elements={[
          // {link:"iceberry", color: 'white', backgroundColor: '#054F3D', image: Hyperlapse0, action:"Дизайн и разработка", title: "Айсберри", subtitle:"SaaS уровня enterprise"},
          // {link:"qrcode", color: 'black', backgroundColor: '#C0BAFC', image: Hyperlapse2, action:"Дизайн веб-приложения", title: "CheckQR.ru", subtitle:"Веб-приложение для быстрой проверки QR-кода"},
          // {link:"simplerouting", color: 'black', backgroundColor: '#89C49D', image: Hyperlapse1, action:"Мобильное приложение", title: "Let's Movie", subtitle:"Простое приложение поиска фильмов"},
          /*{link:"driver", color: 'black', backgroundColor: '#89C49D', image: Hyperlapse3, action:"Мобильное приложение", title: "SimpleRouting", subtitle:"Платформа для мониторинга курьеров"},*/
        ]}
    />
    <div className="Landing__box">
    <div className="Landing__all">

      <Blocks>

        <Block kind={1} link="https://t.me/yetigames" image={YetiTile} text="Of of the most popular CIS gaming Telegram media" hidden="Издание и сервис по отслеживанию консолей"/>
        <Block kind={2} link="https://checkqr.ru/" image={QRTile} text="CheckQR Covid-19 vaccination certificate verification software" hidden="Разработка платформы проверки цифровых кодов вакцинации"/>
        <Block kind={2} link="https://ps5status.ru/" image={PS5StatusTile} text="PS5Status platform for console monitoring"/>
        <Block kind={2} link="https://proxy.cafe/" image={ProxyCafeTile} text="Proxy.cafe backend and infrastructure"/>
        <Block kind={2} image={StonksTile} text="CIS's popular media stock exchange game" hidden="Игровой проект для популярных российских сайтов TJournal и DTF, для
«торговли акциями» известных пользователей сайта"/>
        <Block link="https://cards.yadda.io" kind={1} image={SimployalTile} text="Loyalty platform for cafe and barbershop" hidden="Создание системы лояльности и предзаказа для заведений, для которой
не требуется отдельное приложение, которую можно встроить в виде веб-страницы в любую соцсеть"/>
        <Block kind={2} image={LetsTile} text="Movie search app Let's Movie" hidden="Разработка кросс-платформенного мобильного приложения поиска фильмов для просмотра"/>
        <Block kind={1} link="https://getclub.link/" image={ClubTile} text="Clubhouse links beautifier" hidden="Сервис для генерирации красивых приглашений на встречи в Clubhouse
с указанием даты, времени и спикеров"/>

        <CopyrightBlock/>
      </Blocks>

      {/*
      <div className="Landing__block">
      <a href="https://checkqr.ru" style={{textDecoration: 'none'}} target="blank"><div className="Landing__block__name Landing__block__name-hyper">
          CovidCertificate
          <span style={{fontFamily: 'Manrope', float: 'right', fontWeight: 200}} className="nostroke">→</span>
        </div></a>
        <div className="Landing__block__description">Сервис для быстрой и удобной проверки QR-кодов с сертификатом вакцинации для Москвы и МО прямо в браузере</div>
        <img className="Landing__block__banner" src={qr}/>
        <div className="Landing__block__bottom">
          <span style={{}}>Сделано: Дизайн</span>
          <span style={{float: 'right'}}>Бекэнд: <a style={{color: 'white'}} href="https://yadda.io" target="blank">Yadda</a></span>
        </div>
      </div>

      <div className="Landing__block">
      <a href="https://mcuz.ru" style={{textDecoration: 'none'}} target="blank"><div className="Landing__block__name Landing__block__name-hyper">
          MLPC Website
          <span style={{fontFamily: 'Manrope', float: 'right', fontWeight: 200}} className="nostroke">→</span>
        </div></a>
        <div className="Landing__block__description">Красивый и удобный сайт для Московского центра юридической защиты</div>
        <img className="Landing__block__banner" src={jury}/>
        <div className="Landing__block__bottom">
          <span style={{}}>Сделано: Лендинг</span>
        </div>
      </div>

      <div className="Landing__block">
        <div className="Landing__block__name Landing__block__name-hyper">
          Simple Routing
        </div>
        <div className="Landing__block__description">SimpleRouting – умная платформа для одного из крупных игроков рынка доставки, осуществляющая<br/>расчёт маршрутизации с большим количеством параметров, а также помогающая с координацией курьеров</div>
        <img className="Landing__block__banner" src={simple}/>
        <div className="Landing__block__bottom">
          <span style={{}}>Сделано: Разработка веб-приложения, UI/UX дизайн</span>
          <span style={{float: 'right'}}>Бекэнд / Алгоритмы: <a style={{color: 'white'}} href="https://github.com/dmitrypl" target="blank">Simple Routing</a></span>
        </div>
      </div>

      <div className="Landing__block">
        <div className="Landing__block__name Landing__block__name-hyper">
          Driver App
        </div>
        <div className="Landing__block__description">Приложение для курьера мониторит положение и состояние заказов и помогает курьеру строить свой маршрут между точками</div>
        <img className="Landing__block__banner" src={driver}/>
        <div className="Landing__block__bottom">
          <span style={{}}>Сделано: Разработка мобильного приложения, UI/UX дизайн</span>
          <span style={{float: 'right'}}>Бекэнд: <a style={{color: 'white'}} href="https://brcl.ru" target="blank"></a></span>
        </div>
      </div>

      <div className="Landing__block">
        <div className="Landing__block__name Landing__block__name-hyper">
          Let's Movie
        </div>
        <div className="Landing__block__description">Мобильное приложение для удобного выбора фильмов и сериалов для просмотра <br/>среди баз популярных российских и зарубежных сервисов</div>
        <img className="Landing__block__banner" src={lets}/>
        <div className="Landing__block__bottom">
          <span style={{}}>Сделано: Разработка мобильного приложения для iOS и Android на ReactNative</span>
          <span style={{float: 'right'}}>Бекэнд: <a style={{color: 'white'}} href="https://yadda.io" target="blank">Yadda</a></span>
        </div>
      </div>

      <div className="Landing__block">
        <div className="Landing__block__name Landing__block__name-hyper">
          Logistics App
        </div>
        <div className="Landing__block__description">Веб-приложение для логиста, позволяющее удобно создавать и остлеживать маршруты курьеров</div>
        <img className="Landing__block__banner" src={iceberry}/>
        <div className="Landing__block__bottom">
          <span style={{}}>Сделано: Разработка веб-приложения, UI/UX дизайн</span>
          <span style={{float: 'right'}}>Бекэнд: <a style={{color: 'white'}} href="https://github.com/dmitrypl" target="blank">Simple Routing</a></span>
        </div>
      </div>

      <div className="Landing__block">
        <a href="https://madrich.io" style={{textDecoration: 'none'}} target="blank"><div className="Landing__block__name Landing__block__name-hyper">
          Madrich
          <span style={{fontFamily: 'Manrope', float: 'right', fontWeight: 200}} className="nostroke">→</span>
        </div></a>
        <div className="Landing__block__description">Madrich.IO – удобная платформа для курьерской маршрутизации в формате SaaS, позволяющая сократить<br/>расходы на логистику заказов более чем на 20%</div>
        <img className="Landing__block__banner" src={madrich}/>
        <div className="Landing__block__bottom">
          <span style={{}}>Сделано: Помощь в веб-разработке приложения</span>
          <span style={{float: 'right'}}>Бекэнд: <a style={{color: 'white'}} href="https://madrich.io" target="blank">Madrich</a></span>
        </div>
      </div>

      <div className="Landing__block">
        <a href="https://simployal.com" style={{textDecoration: 'none'}} target="blank"><div className="Landing__block__name Landing__block__name-hyper">
          Simployal
          <span style={{fontFamily: 'Manrope', float: 'right', fontWeight: 200}} className="nostroke">→</span>
        </div></a>
        <div className="Landing__block__description">Веб-система карты лояльности и предзаказа для заведений, для которой<br/>не требуется отдельное приложение, которую можно встроить в виде веб-страницы в любую соцсеть</div>
        <img className="Landing__block__banner" src={simployal}/>
        <div className="Landing__block__bottom">
          <span style={{}}>Сделано: Разработка веб-приложения карты лояльности, UI/UX дизайн</span>
          <span style={{float: 'right'}}>Бекэнд: <a style={{color: 'white'}} href="https://yadda.io" target="blank">Yadda</a></span>
        </div>
      </div>

      <div className="Landing__block">
        <a href="https://stonks.xyz" style={{textDecoration: 'none'}} target="blank"><div className="Landing__block__name Landing__block__name-hyper">
          Stonks
          <span style={{fontFamily: 'Manrope', float: 'right', fontWeight: 200}} className="nostroke">→</span>
        </div></a>
        <div className="Landing__block__description">Stonks.xyz – игровой проект для популярных российских сайтов TJournal и DTF, для <br/>торговли «‎акциями» известных пользователей сайта</div>
        <img className="Landing__block__banner" src={stonks}/>
        <div className="Landing__block__bottom">
          <span style={{}}>Сделано: Разработка веб-приложения, UI/UX дизайн</span>
          <span style={{float: 'right'}}>Бекэнд: <a style={{color: 'white'}} href="https://yadda.io" target="blank">Yadda</a></span>
        </div>
      </div>

      <div className="Landing__block">
        <a href="https://getclub.link" style={{textDecoration: 'none'}} target="blank"><div className="Landing__block__name Landing__block__name-hyper">
          Clublink
          <span style={{fontFamily: 'Manrope', float: 'right', fontWeight: 200}} className="nostroke">→</span>
        </div></a>
        <div className="Landing__block__description">GetClub.Link – сервис для генерирации красивых приглашений на встречи в Clubhouse<br/>с указанием даты, времени и спикеров</div>
        <img className="Landing__block__banner" src={getclub}/>
        <div className="Landing__block__bottom">
          <span style={{}}>Сделано: Разработка веб-приложения</span>
          <span style={{float: 'right'}}>Бекэнд: <a style={{color: 'white'}} href="https://yadda.io" target="blank">Yadda</a></span>
        </div>
      </div>

      <div className="Landing__block">
        <a style={{textDecoration: 'none'}} target="blank"><div className="Landing__block__name Landing__block__name-hyper">
          Detlax EDM
        </div></a>
        <div className="Landing__block__description">Detlax EDM – небольшая функциональная платформа внутреннего электронного документооборота для одной<br/>из российских компаний</div>
        <img className="Landing__block__banner" src={edm}/>
        <div className="Landing__block__bottom">
          <span style={{}}>Сделано: Разработка веб-приложения, разработка бекэнд на Golang</span>
        </div>
      </div>

      <div className="Landing__block">
        <a href="https://zuzan.com" style={{textDecoration: 'none'}} target="blank"><div className="Landing__block__name Landing__block__name-hyper">
          Zuzan
          <span style={{fontFamily: 'Manrope', float: 'right', fontWeight: 200}} className="nostroke">→</span>
        </div></a>
        <div className="Landing__block__description">Zuzan – сайт для поиска мероприятий в вашем городе и по всему миру</div>
        <img className="Landing__block__banner" src={zuzan}/>
        <div className="Landing__block__bottom">
          <span style={{}}>Сделано: Помощь в разработке фронтенда</span>
        </div>
      </div>

      <div className="Landing__block">
        <a href="" style={{textDecoration: 'none'}} target="blank"><div className="Landing__block__name Landing__block__name-hyper">
          UpTrack
          <span style={{fontFamily: 'Manrope', float: 'right', fontWeight: 200}} className="nostroke">→</span>
        </div></a>
        <div className="Landing__block__description">Платформа готовых методологий, для быстрого запуска стартапов<br/>в области IT</div>
        <img className="Landing__block__banner" src={pitek}/>
        <div className="Landing__block__bottom">
          <span style={{}}>Сделано: Лендинг</span>
        </div>
      </div>

      <div className="Landing__block">
        <div className="Landing__block__name">Что я делаю</div>

        <div className="Landing__block__islands">
          <div className="Landing__block__islands__island">фронтенд (react/vue)</div>
          <div className="Landing__block__islands__island">мобильные приложения (react native/flutter)</div>
          <div className="Landing__block__islands__island">макетирование в figma</div>
          <br/>
          <div className="Landing__block__islands__island">интерфейсы для enterprise-решений</div>
          <div className="Landing__block__islands__island">интранет и личные кабинеты</div>
          <div className="Landing__block__islands__island">mvp для стартапов</div>
          <br/>
          <div className="Landing__block__islands__island">saas (веб-приложения)</div>
          <div className="Landing__block__islands__island">ui/ux исследования</div>
          <div className="Landing__block__islands__island">NodeJS</div>
          <div className="Landing__block__islands__island">Postgres/MongoDB</div>
        </div>

        <div className="Landing__block__name Landing__block__name-small">если нужно для проекта, мои коллеги качественно дополнят:</div>

        <div className="Landing__block__islands">
          <div className="Landing__block__islands__island">боты для соцсетей</div>
          <div className="Landing__block__islands__island">нативные (перформанс) мобильные приложения</div>
          <br/>
          <div className="Landing__block__islands__island">нагруженный бэкенд (go / java / kotlin / python + aws / gcp / azure)</div>
          <br/>
          <div className="Landing__block__islands__island">администрирование серверов / docker / ci-cd</div>
          <div className="Landing__block__islands__island">иллюстрации для приложения</div>
          <br/>
          <div className="Landing__block__islands__island">cjm / пользовательские исследования</div>
          <div className="Landing__block__islands__island">брендинг и нэйминг</div>
          <div className="Landing__block__islands__island">копирайтинг</div>
        </div>
      </div>*/}

    </div></div></>
  );
}

export default App;

//box-shadow: 0px 0px 1000px 1000px rgba(0, 0, 0, 0.11)

import { BrowserRouter as Router, Redirect, Route } from "react-router-dom";

import Lander from "./views/landing/index.js";
import Unautorized from "./views/403/index.js";
import Header from "./components/header/header.js";

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <Route exact path="/403/" component={Unautorized} />
        <Route exact path="/403">
          <Redirect to="/403/" />
        </Route>
        <Route exact path="/" render={Lander} />
      </Router>
    </div>
  );
}

export default App;

//box-shadow: 0px 0px 1000px 1000px rgba(0, 0, 0, 0.11)
